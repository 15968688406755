const initialState = () => ({
  selectedItem: null,
  filtros_componente: null,
  status_array: null,
  filtros: {
    datafrom: null,
    datato: null,
    cliente: null,
    moeda: null,
    empresa: null,
    status: null,
    status_cliente: null,
    fornecedor: null,
    usuario: null,
    caixa: null,
    caixapdv: null,
    caixa_af_id: null,
    categoria: null,
    marca: null,
    deposito: null,
    deposito_vinculado: null,
    forma_pagamento: null,
    plano_contas: null,
    group_compras: null,
    group_contas_receber: null,
    group_contas_pagar: null,
    group_vendas: null,
    produtos_ativos: null,
  },
});

const state = initialState();

// getters
const getters = {};

// actions
const actions = {
  reset({ commit }) {
    commit("RESET");
  },
};

// mutations
const mutations = {
  RESET(state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  SET_SELECTED_ITEM(state, payload) {
    state.selectedItem = payload;
  },
  SET__FILTROS_COMPONENTS(state, payload) {
    state.filtros_componente = payload;
  },
  UPDATE_DATAFROM(state, payload) {
    state.filtros.datafrom = payload;
  },
  UPDATE_DATATO(state, payload) {
    state.filtros.datato = payload;
  },
  UPDATE_CLIENTE(state, payload) {
    state.filtros.cliente = payload;
  },
  UPDATE_MOEDA(state, payload) {
    state.filtros.moeda = payload;
  },
  UPDATE_EMPRESA(state, payload) {
    state.filtros.empresa = payload;
  },
  UPDATE_STATUS(state, payload) {
    state.filtros.status = payload;
  },
  UPDATE_STATUS_CLIENTE(state, payload) {
    state.filtros.status_cliente = payload;
  },
  UPDATE_STATUS_ARRAY(state, payload) {
    state.filtros.status_array = payload;
  },
  UPDATE_FORNECEDOR(state, payload) {
    state.filtros.fornecedor = payload;
  },
  UPDATE_USUARIO(state, payload) {
    state.filtros.usuario = payload;
  },
  UPDATE_CAIXA(state, payload) {
    state.filtros.caixa = payload;
  },
  UPDATE_CAIXA_PDV(state, payload) {
    state.filtros.caixapdv = payload;
  },
  UPDATE_CAIXA_AF_ID(state, payload) {
    state.filtros.caixa_af_id = payload;
  },
  UPDATE_CATEGORIA(state, payload) {
    state.filtros.categoria = payload;
  },
  UPDATE_MARCA(state, payload) {
    state.filtros.marca = payload;
  },
  UPDATE_DEPOSITO(state, payload) {
    state.filtros.deposito = payload;
  },
  UPDATE_DEPOSITO_VINCULADO(state, payload) {
    state.filtros.deposito_vinculado = payload;
  },
  UPDATE_FORMA_PAGAMENTO(state, payload) {
    state.filtros.forma_pagamento = payload;
  },
  UPDATE_PLANO_CONTAS(state, payload) {
    state.filtros.plano_contas = payload;
  },
  UPDATE_GROUP_COMPRAS(state, payload) {
    state.filtros.group_compras = payload;
  },
  UPDATE_GROUP_CONTAS_RECEBER(state, payload) {
    state.filtros.group_contas_receber = payload;
  },
  UPDATE_GROUP_CONTAS_PAGAR(state, payload) {
    state.filtros.group_contas_pagar = payload;
  },
  UPDATE_GROUP_VENDAS(state, payload) {
    state.filtros.group_vendas = payload;
  },
  UPDATE_PRODUTOS_ATIVOS(state, payload) {
    state.filtros.produtos_ativos = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
