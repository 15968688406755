const routes = [
  {
    path: "/versoes",
    component: () => import("@/views/VersoesSistema.vue"),
    meta: {
      menu: "dashboard",
      title: { text: "global.versoes" },
      icon: "mdi-view-list",
      breadcrumb: [
        { text: "global.home", to: { path: "/" } },
        { text: "global.versoes"},
      ],
  
    },
  },
];

export default routes;
